import { Injectable } from "@angular/core";
import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor";
import { AlertController, Platform } from "@ionic/angular";
import { User } from './user';
import { HttpClient } from "@angular/common/http";
import { AnalyticsService } from "./analytics.service";
import { ProService } from "./pro.service";
import { PushService } from "./push.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
  })
  export class PurchaseService {
    public isUserPro: boolean = false;
    public trialEligible: boolean = false;
    constructor(
        public platform: Platform,
        public httpClient: HttpClient,
        public alertCtrl: AlertController,
        public analyticsService: AnalyticsService,
        public proService: ProService,
        public pushService: PushService,
        public router: Router
        ) { 
    }

    public async init(userid: string)
    {
        if(this.platform.is("android"))
        {
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
          await Purchases.configure({apiKey: "goog_RsZByCRDPpTYBXLVAlfuIhvbdkE", appUserID: userid});
          await Purchases.collectDeviceIdentifiers();
          console.log("Purchases initialized for Android")
        }
        else if(this.platform.is("ios"))
        {
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
          await Purchases.configure({apiKey: "appl_rLwmcknyFvXFEYCSsRFcmkiGnmX", appUserID: userid});
          await Purchases.collectDeviceIdentifiers();
          await Purchases.enableAdServicesAttributionTokenCollection();
          console.log("Purchases initialized for iOS")
        }
        if(this.platform.is("capacitor"))
        {
            //await this.analyticsService.setAdjustID();
            //await this.analyticsService.setAppsFlyerID();
            await Purchases.setPushToken({pushToken: await this.pushService.getToken()});
            await this.analyticsService.setRevCatCampaignData();
        }
        this.trialEligible = await this.checkTrial();
    }

    public async isPro(): Promise<boolean> {
      console.log("ISPRO")
        let purchasesPro = false;
        if(this.platform.is("capacitor"))
        {
            let customerInfo = (await Purchases.getCustomerInfo()).customerInfo;
            if(customerInfo.entitlements.active["pro"])
            {
                purchasesPro = customerInfo.entitlements.active["pro"].isActive;
            }
        }
        console.log("purchasesPro " + purchasesPro)
        //User is not pro in RevenueCat
        if(!purchasesPro)
        {
            //Check if user is pro in our backend
            let user = await this.getUserApi();
            if(user && user.school != undefined && user.school.school_id != undefined && user.school.school_id != "")
            {
              this.isUserPro = await this.proService.checkPro(user.school.school_id);
              return this.isUserPro;
            }
            this.isUserPro = await this.proService.checkPro();
            return this.isUserPro;
        }
        else {
            this.isUserPro = purchasesPro;
            return purchasesPro;
        }
    }

    public async getOfferings()
    {
        return await Purchases.getOfferings();
    }

    public async restorePurchases()
    {
        if(await this.check() == "true" && await Purchases.restorePurchases())
        {
            this.analyticsService.trackEvent("Restore Purchases");
            this.showRestoreHint();
        }
    }

    //Check if user is eligble for free trial
    public async checkTrial(): Promise<boolean>
    {
        if(this.platform.is("capacitor"))
        {
            const checkTrialOrIntroductoryPriceEligibility = await Purchases.checkTrialOrIntroductoryPriceEligibility({
              productIdentifiers: ["pro"]
            });
            this.trialEligible = (checkTrialOrIntroductoryPriceEligibility["pro"].status == 2 || checkTrialOrIntroductoryPriceEligibility["pro"].status == 0);
            return this.trialEligible;
        }
        else {
            this.trialEligible = false;
            return false;
        }
    }

    async showRestoreHint() {
        const alert = await this.alertCtrl.create({
          cssClass: "hint",
          header: "Erfolgreich",
          message: "Käufe, wenn vorhanden, wiederhergestellt.",
          buttons: [
            {
              text: "Bestätigen",
              role: "cancel",
              handler: () => {
              }
            }]
        });
        await alert.present();
      }

    public async getUserApi(): Promise<User>
    {
        return await this.httpClient.get<User>(`user`).toPromise();
    }
    
    public async check(): Promise<string>
    {
        let result = "true";
        if(!this.platform.is("capacitor")) { result = "platform"}
        else if(!await this.getUserApi()) { result = "auth"}
        else if(await this.isPro()){result = "already"}
        return result;
    }

    public async setPromocode(partnerName: string, code: string): Promise<void>
    {
        Purchases.setAttributes({"promocode": code});
        await this.analyticsService.trackEvent("Set Promocode", {promocode: code});
        //return Purchases.setCampaign(partnerName);
    }

    public codePrompt()
    {
        if(this.platform.is("ios"))
        {
            Purchases.presentCodeRedemptionSheet();
        }
    }

    public async selectCodeType() {
        const iosAlert = await this.alertCtrl.create({
          cssClass: "quitAlert",
          header: "Code einlösen",
          message: "Wähle welche Art von Code Du einlösen möchtest.",
          buttons: [
            {
              text: "Influencer Code",
              handler: () => {
                this.router.navigate(["influencer"]);
              }
            },
            {
              text: "Rabattcode",
              handler: () => {
                this.codePrompt();
              }
            }, {
            text: "Pro Code",
            handler: () => {
              this.router.navigate(["pro"]);
            }
          },
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
            }
          },
          ]
        });
    
        const androidAlert = await this.alertCtrl.create({
            cssClass: "quitAlert",
            header: "Code einlösen",
            message: "Wähle welche Art von Code Du einlösen möchtest.",
            buttons: [
              {
                text: "Influencer Code",
                handler: () => {
                  this.router.navigate(["influencer"]);
                }
              }, {
              text: "Pro Code",
              handler: () => {
                this.router.navigate(["pro"]);
              }
            },
            {
              text: "Abbrechen",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
              }
            },
            ]
          });
    
        if(this.platform.is("ios"))
        {
          await iosAlert.present();
        }
        else {
          await androidAlert.present();
          //this.router.navigate(["pro"]);
        }
      }

    public async setReminder()
    {
        let reminderDate = new Date();
        reminderDate = new Date(reminderDate.getTime() + 1000 * 60 * 60 * 24 * 5);
        reminderDate.setHours(18);
        reminderDate.setMinutes(0);
        reminderDate.setSeconds(0);
        await this.pushService.scheduleLocal(
        {
          id: 1, title: "Deine Testphase endet bald", 
          text: 'Du musst nichts weiter tun, um unbeschränkt weiterzulernen.',
          foreground: false,
          data: {},
          trigger: { at: reminderDate },
        });
        await this.analyticsService.trackEvent("Set Trial Reminder");
    }

    public promoEligible()
    {
        //return true;
        return (!this.isUserPro && this.trialEligible);
    }

    public setAppsFlyerID(id: string) {
        console.log("SETAPPSFLYER ID; ID: " + id)
        return Purchases.setAppsflyerID({ appsflyerID: id });
    }

    public setPartner(value: string)
    {
        Purchases.setCreative({ creative: value });
        this.analyticsService.trackEvent("Set Partnercode", {code: value});
        return Purchases.setAttributes({"partner": value});
    }
}