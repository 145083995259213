import firebase from "firebase/compat/app"; 
import "firebase/compat/auth" 
import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "./user.service";
import { ToastController } from "@ionic/angular";
import { Platform } from "@ionic/angular";
import { AnalyticsService } from "./analytics.service";
import { PurchaseService } from "./purchase.service";
import { DailyStreakService } from "./daily-streak.service";
//import "firebase/compat/auth";
import { SignInWithApple, AppleSignInResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
//import { Auth, getAuth } from 'firebase/auth';
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { take } from "rxjs/operators";
//import { linkWithCredential, OAuthProvider, firebase.auth.UserCredential, GoogleAuthProvider, EmailAuthProvider, reauthenticateWithCredential, User, AuthProvider, AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FirebaseAuthentication, Persistence } from '@capacitor-firebase/authentication';
import { Browser } from "@capacitor/browser";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

/**
 * Service that handles authentication, registration and password-resets.
 * Should only do requests to Firebase.
 */
@Injectable({
  providedIn: "root"
})
export class AuthService {
  public fireUser?: firebase.User;
  public reauth: boolean = false;
  public anon: boolean = false;
  public codeVerifier: string;
  //GoogleAuthProvider: GoogleAuthProvider;

  /**
   * Returns if the user is logged in.
   */
  get isLoggedIn(): boolean {
    return !!this.fireUser && !this.isAnonymous;
  }

  /**
   * Return if the users email is verified.
   */
  get isEmailVerified(): boolean {
    return !!this.fireUser?.emailVerified;
  }

  /**
   * Return if the user is signed-in as anonymous.
   */
  get isAnonymous(): boolean {
    return !!this.fireUser?.isAnonymous;
  }

  /**
   * Return if the user is signed-in via social provider.
   */
  get isSocial(): boolean {
    return !!this.fireUser?.displayName;
  }

  constructor(
    public readonly auth: AngularFireAuth,
    public readonly analytics: AngularFireAnalytics,
    public readonly userService: UserService,
    public readonly purchaseService: PurchaseService,
    public readonly router: Router,
    public toastController: ToastController,
    public platform: Platform,
    private analyticsService: AnalyticsService,
    public dailyStreak: DailyStreakService,
    public appleSignIn: SignInWithApple,
    public http: HttpClient
  ) {
    // Subscribe to the authState to hold the user within this service.
    this.userService.user$.subscribe(user => {
      if (user == null) {
        return;
      }
      else if (this.router.url.includes("reauth")) {
        this.reauth = true;
      }
      else if (this.router.url.includes("anon")) {
        this.anon = true;
      // ...

      }
      if (user.role === "Admin") {
        analytics.setAnalyticsCollectionEnabled(false);
        this.analyticsService.MPOptOut();
        this.analyticsService.GAOptOut();
        this.router.navigateByUrl("tabs/dashboard", {replaceUrl: true});
      } else if (user && window.location.href.indexOf("exercise") <= 0) {
        //User is not an admin, not anonymously logged in, not reauthenticating
        if(!this.reauth && !this.anon && window.location.href.endsWith("start") || window.location.href.endsWith("login") || window.location.href.endsWith("register"))
        {
          	this.router.navigate(["tabs/home"], {replaceUrl: true});
        }

        //User is logged in with an account
        if(user.role == "User")
        {
          this.purchaseService.init(user.uid);
        }
      }
    });
    this.auth.user.subscribe(async user => {
      if (user == null) {
        return;
      }
      else if (this.router.url.includes("reauth")) {
        this.reauth = true;
      }
      this.userService.setCurrentUser(user);
      //this.gplus.trySilentLogin();
      if(this.platform.is("capacitor"))
      {
        this.analyticsService.setIdentity(user.uid);
      }
      //Check if user exists in Firebase but not in backend
      if(!this.reauth && user && !user.isAnonymous && !await userService.doesUserExist(user.uid) && await this.doesFireUserExist(user))
      {
        console.log(`User with id:  ${user.uid} could not be found in db BUT in Firebase!`);
        this.router.navigate(["username"]);
      }
      // Update the users photoURL on social sign-in
      if (this.isSocial && user && user.photoURL !== this.userService.user?.photoURL) {
        this.userService.updateUser({ photoURL: user.photoURL }, user);
      }
      this.fireUser = user;
    });
    this.auth.idToken.subscribe(token => {
      if (token && isDevMode()) {
        localStorage.setItem("access_token", token);
      }
    });
    if (this.auth.user) {
      this.auth.setPersistence("local");
      FirebaseAuthentication.setPersistence({ persistence: Persistence.BrowserLocal });
    }
  }

  /**
   * Authenticates the user by email and password.
   *
   * @param email    - The mail address of the user.
   * @param password - The password of the user.
   */
  public async signInWithEmail(args: {email: string, password: string}): Promise<firebase.auth.UserCredential> {
    let credential = await this.auth.signInWithEmailAndPassword(args.email, args.password);
    if(this.reauth)
    {    
      const authCredential = firebase.auth.EmailAuthProvider.credential(args.email, args.password);
      await this.fireUser.reauthenticateWithCredential(authCredential);
    }
    return credential;
  }

  /**
   * Let the user sign is as a guest. This should result in the user having less options.
   */
  public async signInWithGuest(): Promise<firebase.auth.UserCredential> {
    await this.dailyStreak.setDefaultAnon();
    return await this.auth.signInAnonymously();
  }

  /**
   * Registers a user with a mail address and password.
   * If the user is currently signed in as a guest, the new credentials will be linked.
   *
   * @param email    - The users mail address.
   * @param password - The password to be hashed by the server.
   * @returns the created user if one is created or null if the username is already taken.
   */
  public async registerUser(user: {
    email: string, password: string, username: string, age: number, onboarding: any,
    promocode?: string, name: string,
}): Promise<firebase.auth.UserCredential | null> {
    if (!await this.userService.doesUsernameExist(user.username) /*&& !await this.userService.doesEmailExist(user.email)*/) {
      let userCredentials: firebase.auth.UserCredential | null;
      if (this.fireUser?.isAnonymous) {
        userCredentials = await this.linkAccount(user.email, user.password);
      } else {
        userCredentials = await this.auth.createUserWithEmailAndPassword(user.email, user.password);
      }
      if (userCredentials?.user) {
        //Sign Up successful, MP track event
        this.analyticsService.trackEvent("Sign Up", {"provider": "Email"});
        this.analyticsService.trackConversion("signup", 0);
        localStorage.setItem("access_token", await userCredentials.user.getIdToken());
        const dbUser = await this.userService.createUserInBackend(userCredentials.user, user.username, user.age, user.onboarding, user.promocode);
        await this.userService.setCurrentUser(dbUser);
        await this.sendVerificationMail(userCredentials.user, true);
      }
      return userCredentials;
    } else {
      return null;
    }
  }

  /**
   * Links an anonymous account to the given credentials.
   */
  public async linkAccount(email: string, password: string): Promise<firebase.auth.UserCredential | null> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );

    try {
      await this.analyticsService.trackEvent("Link Account");
      return await this.fireUser.linkWithCredential(credential) ?? null;
    } catch (e) {
      this.showToast();
    }
    return null;
  }

  /**
   * Email verification when new user registers
   */
  public async sendVerificationMail(user: firebase.User, preventNavigation = false): Promise<void> {
    try {
      await this.fireUser.sendEmailVerification();
      if (!preventNavigation) {
        this.router.navigate(["verifyMail"]);
      }
    } catch (error) {
      this.showToast();
    }
  }

  /**
   * Starts the password recovery.
   *
   * @param passwordResetEmail - The email-address to send the recover password mail to.
   */
  public async recoverPassword(passwordResetEmail: string): Promise<String> {
    try {
      await this.auth.sendPasswordResetEmail(passwordResetEmail);
      this.router.navigateByUrl("login", {replaceUrl: true});
      return "success";
    } catch (error) {
      return "error";
    }
  }

  /**
   * Opens a Gmail login prompt.
   */
  public async signInWithGoogle(): Promise<void> {
    if(this.platform.is("capacitor"))
    {
      console.log("Native Google Sign In")
      return await this.nativeGoogleSignIn();
    }
    else {
      console.log("Web Google Sign In")
      return await this.signInWithAuthProvider(new firebase.auth.GoogleAuthProvider());
    }
  }

  public async nativeGoogleSignIn(): Promise<void> {
    try {
      const result = await FirebaseAuthentication.signInWithGoogle();
      const authCredential = firebase.auth.GoogleAuthProvider.credential(result.credential.idToken);
      await this.auth.signInWithCredential(authCredential);
      this.auth.authState.subscribe(user => { this.fireUser = user; });
      console.log("FIREUSER: " + this.fireUser)
      //User wants to reauthenticate
      if (this.reauth) {
        this.fireUser.reauthenticateWithCredential(authCredential);
      }

      if (result.additionalUserInfo.isNewUser) {
        this.analyticsService.trackEvent("Sign Up", {"provider": "Google"});
        this.router.navigate(["username"]);
        return;
      }
      else {
        this.analyticsService.trackEvent("Sign In", {"provider": "Google"});
        this.router.navigate(["tabs/home"], {replaceUrl: true});
      }
    } catch(error) {
      console.log("Fehler: " + error);
    }
  }

  /**
   * Base function for singing in with the different auth providers (Facebook, Microsoft, Gmail, Github).
   *
   * @param provider - The provider to be used for authentication.
   */
  private async signInWithAuthProvider(provider: firebase.auth.AuthProvider): Promise<void> {
    try {
      const userCredential = await this.auth.signInWithPopup(provider);
      //User wants to reauthenticate
      if (this.reauth) {
        this.fireUser.reauthenticateWithCredential(userCredential.credential);
      }
      if (userCredential.additionalUserInfo.isNewUser) {
        this.analyticsService.trackEvent("Sign Up", {"provider": provider.providerId});
        this.router.navigate(["username"], {replaceUrl: true});
        return;
      } else {
        this.analyticsService.trackEvent("Sign In", {"provider": provider.providerId});
        this.router.navigate(["tabs/home"], {replaceUrl: true});
      }
    } catch (e) {
      this.showToast("Fehler: " + e);
    }
  }

  public async signInWithApple()
  {
    if(this.platform.is("ios"))
    {
      await this.appleSignIn.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      }).then(async (res: AppleSignInResponse) => {
        // Create a custom OAuth provider    
        const provider = new firebase.auth.OAuthProvider('apple.com');
     
        // Create sign in credentials with our token
        const credential = provider.credential({
          idToken: res.identityToken
        });
        
        // Call the sign in with our created credentials
        const userCredential = await this.auth.signInWithCredential(credential);
        if(userCredential.additionalUserInfo.isNewUser)
        {
          this.analyticsService.trackEvent("Sign Up", {"provider": "Apple"});
          this.router.navigate(["username"], {replaceUrl: true});
          return;
        }
        else if(this.reauth) {
          //User wants to reauthenticate
          this.fireUser.reauthenticateWithCredential(credential);
        }
        else {
          this.analyticsService.trackEvent("Sign In", {"provider": "Apple"});
          this.router.navigate(["tabs/home"], {replaceUrl: true});
        }
      });
    }
  }

  generateCodeVerifier(length: number = 128): string {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
  
    // Wandeln Sie das Array in eine Base64URL-kodierte Zeichenkette um
    const codeVerifier = this.base64UrlEncode(array);
  
    // Stellen Sie sicher, dass der Code Verifier die richtige Länge hat
    // Der Code Verifier muss zwischen 43 und 128 Zeichen lang sein
    if (codeVerifier.length < 43) {
      // Wiederholen, bis die Mindestlänge erreicht ist
      return this.generateCodeVerifier(length + 10);
    } else if (codeVerifier.length > 128) {
      // Schneiden Sie auf 128 Zeichen zu
      const truncatedVerifier = codeVerifier.substr(0, 128);
      this.codeVerifier = truncatedVerifier;
      localStorage.setItem('codeVerifier', truncatedVerifier);
      return truncatedVerifier;
    } else {
      this.codeVerifier = codeVerifier;
      localStorage.setItem('codeVerifier', codeVerifier);
      return codeVerifier;
    }
  }
  
  async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = this.base64UrlEncode(digest);
    return base64Digest;
  }  

  base64UrlEncode(arrayBuffer: ArrayBuffer | Uint8Array): string {
    let bytes: Uint8Array;
    if (arrayBuffer instanceof ArrayBuffer) {
      bytes = new Uint8Array(arrayBuffer);
    } else {
      bytes = arrayBuffer;
    }
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64String = window.btoa(binary);
    // Base64URL-Encodierung
    return base64String
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }  

  public async signInWithEduplaces()
  {
    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    const clientId = environment.eduplacesClientId;
    let redirectUri = 'grammario://callback';
    const scope = 'openid school school_name school_location school_type school_official_id role groups';
    const state = Math.random().toString(36).substring(2);
    const authorizationEndpoint = environment.eduplacesUrl;

    let url =
    `${authorizationEndpoint}?response_type=code&client_id=${encodeURIComponent(clientId)}` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}` +
    `&state=${encodeURIComponent(state)}&code_challenge=${encodeURIComponent(codeChallenge)}` +
    `&code_challenge_method=S256`;

    // Speichern Sie den Code Verifier und den State
    localStorage.setItem('codeVerifier', codeVerifier);
    localStorage.setItem('oauthState', state);

    if (this.platform.is('capacitor')) {
      // Für Capacitor
      await Browser.open({ url });
    }
    else {
      // Für den Webbrowser
      redirectUri = 'https://sso.grammario.de/callback';
      url =
      `${authorizationEndpoint}?response_type=code&client_id=${encodeURIComponent(clientId)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}` +
      `&state=${encodeURIComponent(state)}&code_challenge=${encodeURIComponent(codeChallenge)}` +
      `&code_challenge_method=S256`;
      window.location.href = url;
    }
  }

  public async reauthenticateWithEduplaces() {
    const user = await this.auth.currentUser;
  
    if (user) {
      try {
        // Erstellen Sie einen neuen eduplaces OAuth Provider
        const provider = new firebase.auth.OAuthProvider('oidc.eduplaces');
  
        // Optional: Scopes hinzufügen
        provider.addScope('openid school school_name school_location school_type school_official_id role groups');
  
        // Re-Authentifizierung mit Popup
        await user.reauthenticateWithPopup(provider);
      }
      catch (error) {
        console.error('Fehler bei der Re-Authentifizierung:', error);
      }
    }
  }

  async handleAuthCallback(code: string, state: string) {
    const savedState = localStorage.getItem('oauthState');
    const codeVerifier = localStorage.getItem('codeVerifier');
  
    if (state !== savedState) {
      console.error('Ungültiger State');
      return;
    }
  
    if (!code || !codeVerifier) {
      console.error('Fehlender Autorisierungscode oder Code Verifier');
      return;
    }
    await Browser.close();
    // Senden Sie den Autorisierungscode und den Code Verifier an Ihr PHP-Backend
    try {
      const response: any = await this.http.post('auth/eduplaces/callback', {
        code,
        codeVerifier
      }).toPromise();
      const customToken = response.customToken;
      let userCredential = await this.auth.signInWithCustomToken(customToken);
      await this.userService.updateEduplacesUser(response.user);
        if(userCredential.additionalUserInfo.isNewUser)
          {
            this.analyticsService.trackEvent("Sign Up", {"provider": "Eduplaces"});
            this.router.navigate(["username"], {replaceUrl: true});
            return;
          }
          else {
            this.analyticsService.trackEvent("Sign In", {"provider": "Eduplaces"});
            this.router.navigate(["tabs/home"], {replaceUrl: true});
          }
          //this.fireUser = userCredential.user;
          console.log("User signed in successfully");
    } catch (error) {
        console.error("Error during sign-in:", error);
    }
  }

  /**
   * Return the token of the current user.
   */
  public async getToken(): Promise<string> {
    if(this.platform.is("capacitor"))
    {
      return (await FirebaseAuthentication.getIdToken()).token;
    }
    else if (this.fireUser){
      return await this.fireUser?.getIdToken() ?? "";
    }
    else {
      return "";
    }
  }

  /**
   * Sign out and remove the authToken from the localStorage.
   */
  public async signOut(): Promise<void> {
    try {
      if (this.isAnonymous) {
        await this.fireUser?.delete();
      }
      await this.auth.signOut();
      this.userService.user = undefined;
      if(this.platform.is("capacitor"))
      {
        /*if(!this.fbx.signOutUser())
        {
          await this.fbx.trySilentLogin();
          await this.fbx.logout()
        }*/
        await this.analyticsService.trackEvent("Sign Out");
        await this.analyticsService.signOut();
      }
      localStorage.removeItem("access_token");
      localStorage.removeItem("onboarding");
      this.router.navigate(["start"], {replaceUrl: true});
    } catch (err: any) {
      this.showToast();
    }
  }

    /**
    * Checks if user exists in firestore
    */
    public async doesFireUserExist(user: firebase.User): Promise<boolean>
    {
        return this.auth.authState.pipe(take(1)).toPromise().then((authUser) => {
            return authUser?.uid === user.uid;
          }).catch(() => false);
    }

  public async showToast(message?: string): Promise<void> {
    let toast = await this.toastController.create({
      message: message ?? "Ein Fehler ist aufgetreten.",
      duration: 3000,
      animated: true,
      position: "bottom",
    });
    await toast.present();
  }
}
